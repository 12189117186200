<template>
  <div>
    <div v-if="getSeriesLoadingState === 'LOADING'" class="spinner-overlay">
      <Spinner />
    </div>
    <Header class="shadow" />

    <div
      v-if="getSeriesLoadingState === 'LOADING_SUCCESS'"
      class="series-page page-wrap"
      style="background-color: #050b06"
    >
      <div class="container" style="background-color: #050b06">
        <div class="series-details-wrap" style="background-color: #050b06">
          <div
            class="row no-gutters series-header"
            style="background-color: #050b06"
          >
            <div class="col-12 col-md-5 col-sm-12">
              <div
                class="book-image-container"
                style="background-color: #050b06"
              >
                <div class="book-image" style="width: 100%">
                  <img
                    class="w-100 h-100 cover-image"
                    style="object-fit: fill; border-radius: 10px"
                    :src="getSeriesData.coverImageUrl"
                    :alt="getSeriesData.title"
                  />
                </div>
              </div>
            </div>

            <div class="col-12 col-md-5 col-sm-12 series-details">
              <h1 class="title text">{{ getSeriesData.title }}</h1>
              <div
                class="container rating"
                style="margin-top: 15px; margin-bottom: 4px"
              >
                <span class="rating-star-img">
                  <img
                    class="rating-star-img"
                    alt=""
                    src="https://comicassets.pratilipi.com/website/img/icon-star.svg"
                /></span>

                <span
                  v-if="getSeriesData.comicSocial"
                  class="title-star"
                  style="color: #ffff"
                >
                  {{ getSeriesData.comicSocial.averageRating | round(1) }}
                </span>
              </div>

              <div v-if="getSeriesData.summary" class="summary">
                {{ getSeriesData.summary }}
              </div>

              <!-- <div v-for="(eachWidget, index) in getSeriesChapters" :key="eachWidget.listPageUrl" > -->
              <router-link
                :to="{
                  name: 'home-episode',
                  params: {
                    pratilipiId: getSeriesChapters[0]
                      ? getSeriesChapters[0].pratilipiId
                      : null,
                  },
                }"
                @click.native="
                  triggerClickReadEvent(
                    getSeriesChapters[0]
                      ? getSeriesChapters[0].pratilipiId
                      : null
                  )
                "
              >
                <button
                  type="button"
                  class="btn btn-success btn-lg btn-block firstepisode"
                  style="background-color: #17bf63"
                >
                  पहला एपिसोड पढ़े
                </button>
              </router-link>
              <div>
                <ShareStrip
                  :data="getSeriesData"
                  :type="'SERIES'"
                  class="share button"
                />
              </div>
            </div>
          </div>
          <div>
            <hr class="Line-Copy" />
          </div>
          <div class="container">
            <div class="row no-gutters series-list">
              <div class="col-12" style="background-color: #050b06">
                <h2 class="head-title">एपिसोड्स</h2>
              </div>
            </div>
          </div>

          <div class="heading container">
            <div
              v-for="(eachWidget, index) in getSeriesChapters"
              :key="eachWidget.listPageUrl"
              class="section"
              style="color: #fff"
            >
              <div v-if="index < 6 && index % 2 == 0" class="row episode-cards">
                <div
                  v-if="getSeriesChapters[index]"
                  class="col-md-6 col-12"
                  style="padding-left: 5px"
                >
                  <router-link
                    :to="{
                      name: 'home-episode',
                      params: { pratilipiId: eachWidget.pratilipiId },
                    }"
                    @click.native="triggerClickReadEvent(eachWidget.pratilipiId,index+1)"
                  >
                    <div class="card mb-3" style="background-color: #050b06">
                      <div class="row g-0">
                        <div class="col-md-4 col-3 w-100 h-100">
                          <img
                            class="episode-image"
                            :src="eachWidget.coverImageUrl"
                            alt="..."
                          />
                        </div>
                        <div class="col-md-6 col-6">
                          <p
                            class="card-text text-episode"
                            style="text-align: left; color: #fff"
                          >
                            <small class="text-muted episode-number">
                              एपिसोड्स-{{ index + 1 }}</small
                            >
                          </p>
                          <p
                            class="card-text text-start episode-title"
                            style="text-align: left; color: #fff"
                          >
                            {{ eachWidget.displayTitle }}
                          </p>
                          <p
                            class="card-text text-start episode-likes"
                            style="text-align: left"
                          >
                            <small class="text-muted"
                              >{{ getSeriesChaptersLikes[index] }} likes</small
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div
                  v-if="getSeriesChapters[index + 1]"
                  class="col-md-6 col-12"
                  style="padding-left: 5px"
                >
                  <div class="card mb-3" style="background-color: #050b06">
                    <router-link
                      :to="{
                        name: 'home-episode',
                        params: {
                          pratilipiId: getSeriesChapters[index + 1].pratilipiId,
                        },
                      }"
                      @click.native="
                        triggerClickReadEvent(eachWidget, index+2)
                      "
                    >
                      <div class="row g-0">
                        <div class="col-md-4 col-3 w-100 h-100">
                          <img
                            class="episode-image"
                            :src="getSeriesChapters[index + 1].coverImageUrl"
                            alt="..."
                          />
                        </div>
                        <div class="col-md-6 col-6">
                          <p
                            class="card-text text-episode"
                            style="text-align: left; color: #fff"
                          >
                            <small class="text-muted episode-number">
                              एपिसोड्स-{{ index + 2 }}</small
                            >
                          </p>
                          <p
                            class="card-text text-start episode-title"
                            style="text-align: left; color: #fff"
                          >
                            {{ getSeriesChapters[index + 1].displayTitle }}
                          </p>
                          <p
                            class="card-text text-start episode-likes"
                            style="text-align: left"
                          >
                            <small class="text-muted"
                              >{{
                                getSeriesChaptersLikes[index + 1]
                              }}
                              likes</small
                            >
                          </p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>

            <a
              @click="triggerClickDownloadEvent"
              href="https://get.pratilipicomics.com/4u9FS2EWJgb"
              style="background-color: #050b06"
              class="row h-100 h-100"
            >
              <img
                v-if="getSeriesLoadingState === 'LOADING_SUCCESS'"
                style="max-width: 100%"
                src="https://s3.ap-south-1.amazonaws.com/pratilipi.comic.assets/website/img/download-app-banner-hindi.jpg"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Header from "../components/HeaderV2";
import ShareStrip from "@/components/shareStrip.vue";
import mixins from "@/mixins";
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    ShareStrip,
    Header,
    Spinner,
  },
  mixins: [mixins],
  data() {
    return {
      data: [],
      likes: [],
      seriesData:null
    };
  },
  computed: {
    ...mapGetters("seriespage", [
      "getSeriesData",
      "getSeriesLoadingState",
      "getSeriesChapters",
      "getSeriesChapterLoadingState",
      "getSeriesChaptersOffset",
      "isShowPratilipiRemoveError",
      "getSeriesChaptersLikes",
    ]),
    ...mapGetters(["getUserDetails"]),
  },

  created() {
    this.fetchSeriesDetails(this.$route.params.slug)

    this.fetchSeriesChapters(this.$route.params.slug);

    this.data = this.getSeriesChapters;

  },
  beforeRouteLeave(to, from, next) {
    this.seriesData = this.getSeriesData;
    this.clearStore();
    next();
  },
  watch: {
    getSeriesLoadingState(state) {
      if (state === "LOADING_SUCCESS") {
        this.getlikes(this.getSeriesChapters);
        this.triggerAnanlyticsEvent(`LANDED_SERIES_PAGE`, "CONTROL", {
      SERIES_ID: this.getSeriesData.seriesId,
      utm_source: this.getUrlVars()["utm_source"],
      utm_medium: this.getUrlVars()["utm_medium"],
      utm_campaign: this.getUrlVars()["utm_campaign"],
      LANGUAGE:"HINDI",
      SCREEN_NAME: "seriesSummaryPage",
      SERIES_NAME: this.getSeriesData.title
    });
        this.seriesData=this.getSeriesData;
        this.setPratilipsSeriesData(this.getSeriesData);
      }

    },
    getSeriesChapterLoadingState(state) {
      if (state === "LOADING_SUCCESS") {

        this.setPratilipiParts(
          this.getSeriesChapters.map((pratilipi) => pratilipi.pratilipiId)
        );
      }
    },
  },

  methods: {
    getlikes(pratilipiList) {
      for (i = 0; i < 6; i++) {
        if (pratilipiList[i])
          this.fetchSeriesChaptersLikes(pratilipiList[i].pratilipiId);
      }
    },
    triggerClickReadEvent(pratilipiId, index=0) {
      this.triggerAnanlyticsEvent(`CLICK_EPISODE_CARD`, "CONTROL", {
        SCREEN_NAME: "seriesSummaryPage",
        SERIES_ID: this.seriesData.seriesId,
        SERIES_NAME: this.seriesData.title,
        LANGUAGE:"HINDI",
        UI_POSITION: index + 1,
        SCREEN_NAME: "seriesSummaryPage",
        PRATILIPI: pratilipiId,

      });
    },
    triggerClickDownloadEvent() {
      this.triggerAnanlyticsEvent(`CLICK_DOWNLOAD_APP`, "CONTROL", {
        SCREEN_NAME: "seriesSummaryPage",
        SERIES_ID: this.seriesData.seriesId,
        SERIES_NAME: this.seriesData.title,
        LANGUAGE:"HINDI"
      });
    },

    triggerEvent() {
      this.$router.push("/home-episode");
    },
    openAppDownloadModal() {
      this.triggerAnanlyticsEvent("DOWNLOAD_BOOKM_BOOK", "CONTROL", {
        PRATILIPI_TYPE: "SERIES",
        SCREEN_NAME: "seriesSummaryPage",
        SERIES_ID: this.seriesData.seriesId,
        SERIES_NAME: this.seriesData.title,
        LANGUAGE:"HINDI"
      });
      $("#appDownloadModal").modal("show");
    },
    ...mapActions("seriespage", [
      "fetchSeriesDetails",
      "fetchSeriesChapters",
      "fetchMoreSeriesChapters",
      "addSeriesToLibrary",
      "removeSeriesFromLibrary",
      "resetShowPratilipiRemoveError",
      "fetchSeriesChaptersLikes",
      "clearStore",
    ]),
    ...mapActions("pratilipipage", [
      "fetchPratilipiDetails",

      "fetchMoreSeriesChapters",
      "addSeriesToLibrary",
      "removeSeriesFromLibrary",
      "resetShowPratilipiRemoveError",
      "setPratilipsSeriesData",
      "setPratilipiParts",
    ]),

    getIsNextPratilipiUnderAccess(chapter) {
      return (
        chapter &&
        chapter.pratilipi &&
        chapter.pratilipi.pratilipiEarlyAccess &&
        chapter.pratilipi.pratilipiEarlyAccess.isEarlyAccess
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.rating-star-img {
  padding-top: 2px;
  flex-basis: 10%;
  vertical-align: inherit;
  @media screen and (max-width: 641px) {
    width: 16px;
    height: 16px;

    object-fit: contain;
  }
}
.episode-cards {
  @media screen and (min-width: 641px) {
    height: 195px;
  }
}
.episode-title {
  @media screen and (max-width: 641px) {
    text-align: left;
    color: rgb(255, 255, 255);
    width: 212px;
    height: 19px;
    font-family: NotoSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}
.episode-number {
  @media screen and (max-width: 641px) {
    width: 64px;
    height: 16px;

    font-family: NotoSansDevanagari;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #adadad;
  }
}
.episode-likes {
  @media screen and (max-width: 641px) {
    width: 320px;
    height: 1359px;
    background-color: #050b06;
    height: 0px;
  }
}
.episode-image {
  border-radius: 4px;
  height: 148px;
  width: 148px;
  @media screen and (max-width: 641px) {
    height: 64px !important;
    width: 64px !important;
  }
}
.title-star {
  width: 58px;
  height: 23px;
  margin: 0 0 0 3px;
  font-family: LucidaGrande;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #adadad;
  @media screen and (max-width: 641px) {
    font-size: 24px;
    width: 58px;
    height: 15px;
    margin: 0.5px 0 0.5px -1px;
    font-family: LucidaGrande;
    font-size: 12px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #adadad !important;
    vertical-align: 2px !important;
  }
}
.heading {
  @media screen and (min-width: 641px) {
    margin-top: -35px;
  }
}
.head-title {
  color: #f8f8f8;
  @media screen and (max-width: 641px) {
    width: 228px;
    height: 26px;
    font-family: NotoSansDevanagari;
    font-size: 20px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fefefe;
    margin: 0px 0px 10px -20px !important;
  }
}
.sharebutton1 {
  color: white;
  text-align: center;
  width: 432px;
  height: 45px;
  margin: 16px 272px 32px 0px;
  border-radius: 4px;
  background-color: #17bf63;
  @media screen and (max-width: 641px) {
    margin: 0px;
    width: 100%;
    margin-top: 9px;
    height: 36%;
  }
  .h3 {
    padding: 10px ​305px 44px 127px;
    text-align: center;
  }
}

.text {
  color: #f8f8f8;
  @media screen and (max-width: 641px) {
    font-size: 32px;
    width: 228px;
    height: 26px;

    font-family: NotoSansDevanagari;
    font-size: 20px !important;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fefefe;
    margin: 10px 0px 5px 20px !important;
  }
}
.Line-Copy {
  width: 924px;
  margin-top: 18px;
  margin-right: 227px;
  height: 1px;
  border: solid 1px #202521;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
.series-page {
  background: #f8f8f8;
  min-height: 700px;
  overflow: auto;
  @media screen and (max-width: 992px) {
  }
  .col-md-12.p-0 {
    padding: 0;
  }
  .series-details-wrap {
    @media screen and (min-width: 641px) {
      margin-top: 40px;
    }
  }
  .series-header {
    background: white;
    @media screen and (min-width: 641px) {
      padding-bottom: 20px;
    }
    @media screen and (max-width: 641px) {
      height: 100%;
      margin-top: 5px;
      margin-left: -11px;
      margin-right: -11px;
    }
    &.mobile-view {
      padding: 0 10px;
      padding-top: 10px;
      padding-bottom: 20px;
      @media screen and (min-width: 641px) {
        display: none;
      }
      text-align: left;
      .stats {
        font-size: 13px;
        margin: 5px 0;
        .each-stat {
          display: inline-block;
          padding-right: 10px;
          i {
            color: #6c757d;
            font-size: 15px;
            vertical-align: middle;
          }
          span {
            color: #6c757d;
            display: inline-block;
            vertical-align: middle;
          }
        }
      }
      .summary {
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        bottom: 0;
        margin: 10px 0 15px;
        max-height: 100px;
        white-space: normal;
        overflow: scroll;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        color: #555;
        word-break: break-word;
      }
    }
    .book-image-container {
      display: flex;
      flex-grow: 1;
      justify-content: left;
      .book-image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        position: relative;
        @media screen and (max-width: 359px) {
          height: 192px;
          width: 120px;
        }
        @media screen and (min-width: 360px) and (max-width: 640px) {
          height: 100%;
          width: 137px;
        }
        @media screen and (min-width: 641px) and (max-width: 823px) {
          height: 260px;
          width: 173px;
        }
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
    .series-details {
      @media screen and (min-width: 640px) {
        position: relative;
        padding: 0 10px 0 30px;
      }
      text-align: left;

      .title {
        @media screen and (min-width: 640px) {
          bottom: 266px;
          position: absolute;
        }
        font-size: 30px;
        margin: 95px 0px 0px;
        width: 300px;
        @media screen and (max-width: 640px) {
          font-size: 32px;
          margin: 10px 0px 10px 16px;
        }
      }
      .summary {
        @media screen and (min-width: 640px) {
          bottom: 110px;
          position: absolute;
        }

        @media screen and (max-width: 640px) {
          display: none;
        }
        font-family: KohinoorDevanagari;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: #fefefe;
        text-align: left;
        font-size: 14px;
        line-height: 20px;
        bottom: 0;
        margin: 24px 0 30px;
        width: 500px;
        max-height: 95px;
        white-space: normal;
        overflow: scroll;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        word-break: break-word;
      }
      .share {
        @media screen and (min-width: 640px) {
          bottom: -30px;
          position: absolute;
        }
        @media screen and (max-width: 640px) {
        }
        display: inline-block;
      }
    }
  }
  .series-list {
    margin: 15px 0 60px;
    @media screen and (max-width: 641px) {
      margin: 20px 0px 0px;
    }
    h2 {
      font-size: 24px;
      font-weight: bold;
      text-align: left;
      border-left: 3px solid #0a0a0a;
      padding-left: 10px;
      margin: 16px 0;
      display: block;
      min-height: 28px;
    }
  }
}

.text-episode {
  margin: 40px 0px 0px 0px;
  color: #fff;
  @media screen and (max-width: 641px) {
    margin: 0px;
  }
}

.text-start {
  margin: 0px 0px 0px 0px;
  color: #fff;
}
.firstepisode {
  @media screen and (min-width: 641px) {
    bottom: 72px;
    position: absolute;
  }
  @media screen and (max-width: 641px) {
    background-color: rgb(23, 191, 99);
    /* width: 107px; */
    /* height: 19px; */
    font-family: NotoSansDevanagari;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
}
.btn-block {
  @media screen and (min-width: 641px) {
    display: block;
    width: 93%;
  }
  @media screen and (max-width: 641px) {
    display: block;
    width: 92%;
    margin-left: 16px;
    margin-right: 16px;
  }
}
.rating {
  @media screen and (min-width: 641px) {
    bottom: 216px;
    position: absolute;
  }
  @media screen and (max-width: 641px) {
    margin-left: 6px;
    margin-top: 5px !important;
  }
}
.cover-image {
  @media screen and (max-width: 641px) {
    height: 316px !important;
  }
}
.title {
  @media screen and (min-width: 641px) {
    font-size: 32px;
    width: 228px;
    height: 26px;
    margin: 3px 0px 18.5px 0px;
    font-family: NotoSansDevanagari;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fefefe;
  }
}
</style>
