var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.getSeriesLoadingState === 'LOADING')?_c('div',{staticClass:"spinner-overlay"},[_c('Spinner')],1):_vm._e(),_c('Header',{staticClass:"shadow"}),(_vm.getSeriesLoadingState === 'LOADING_SUCCESS')?_c('div',{staticClass:"series-page page-wrap",staticStyle:{"background-color":"#050b06"}},[_c('div',{staticClass:"container",staticStyle:{"background-color":"#050b06"}},[_c('div',{staticClass:"series-details-wrap",staticStyle:{"background-color":"#050b06"}},[_c('div',{staticClass:"row no-gutters series-header",staticStyle:{"background-color":"#050b06"}},[_c('div',{staticClass:"col-12 col-md-5 col-sm-12"},[_c('div',{staticClass:"book-image-container",staticStyle:{"background-color":"#050b06"}},[_c('div',{staticClass:"book-image",staticStyle:{"width":"100%"}},[_c('img',{staticClass:"w-100 h-100 cover-image",staticStyle:{"object-fit":"fill","border-radius":"10px"},attrs:{"src":_vm.getSeriesData.coverImageUrl,"alt":_vm.getSeriesData.title}})])])]),_c('div',{staticClass:"col-12 col-md-5 col-sm-12 series-details"},[_c('h1',{staticClass:"title text"},[_vm._v(_vm._s(_vm.getSeriesData.title))]),_c('div',{staticClass:"container rating",staticStyle:{"margin-top":"15px","margin-bottom":"4px"}},[_vm._m(0),(_vm.getSeriesData.comicSocial)?_c('span',{staticClass:"title-star",staticStyle:{"color":"#ffff"}},[_vm._v(" "+_vm._s(_vm._f("round")(_vm.getSeriesData.comicSocial.averageRating,1))+" ")]):_vm._e()]),(_vm.getSeriesData.summary)?_c('div',{staticClass:"summary"},[_vm._v(" "+_vm._s(_vm.getSeriesData.summary)+" ")]):_vm._e(),_c('router-link',{attrs:{"to":{
                name: 'home-episode',
                params: {
                  pratilipiId: _vm.getSeriesChapters[0]
                    ? _vm.getSeriesChapters[0].pratilipiId
                    : null,
                },
              }},nativeOn:{"click":function($event){return _vm.triggerClickReadEvent(
                  _vm.getSeriesChapters[0]
                    ? _vm.getSeriesChapters[0].pratilipiId
                    : null
                )}}},[_c('button',{staticClass:"btn btn-success btn-lg btn-block firstepisode",staticStyle:{"background-color":"#17bf63"},attrs:{"type":"button"}},[_vm._v(" पहला एपिसोड पढ़े ")])]),_c('div',[_c('ShareStrip',{staticClass:"share button",attrs:{"data":_vm.getSeriesData,"type":'SERIES'}})],1)],1)]),_vm._m(1),_vm._m(2),_c('div',{staticClass:"heading container"},[_vm._l((_vm.getSeriesChapters),function(eachWidget,index){return _c('div',{key:eachWidget.listPageUrl,staticClass:"section",staticStyle:{"color":"#fff"}},[(index < 6 && index % 2 == 0)?_c('div',{staticClass:"row episode-cards"},[(_vm.getSeriesChapters[index])?_c('div',{staticClass:"col-md-6 col-12",staticStyle:{"padding-left":"5px"}},[_c('router-link',{attrs:{"to":{
                    name: 'home-episode',
                    params: { pratilipiId: eachWidget.pratilipiId },
                  }},nativeOn:{"click":function($event){return _vm.triggerClickReadEvent(eachWidget.pratilipiId,index+1)}}},[_c('div',{staticClass:"card mb-3",staticStyle:{"background-color":"#050b06"}},[_c('div',{staticClass:"row g-0"},[_c('div',{staticClass:"col-md-4 col-3 w-100 h-100"},[_c('img',{staticClass:"episode-image",attrs:{"src":eachWidget.coverImageUrl,"alt":"..."}})]),_c('div',{staticClass:"col-md-6 col-6"},[_c('p',{staticClass:"card-text text-episode",staticStyle:{"text-align":"left","color":"#fff"}},[_c('small',{staticClass:"text-muted episode-number"},[_vm._v(" एपिसोड्स-"+_vm._s(index + 1))])]),_c('p',{staticClass:"card-text text-start episode-title",staticStyle:{"text-align":"left","color":"#fff"}},[_vm._v(" "+_vm._s(eachWidget.displayTitle)+" ")]),_c('p',{staticClass:"card-text text-start episode-likes",staticStyle:{"text-align":"left"}},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.getSeriesChaptersLikes[index])+" likes")])])])])])])],1):_vm._e(),(_vm.getSeriesChapters[index + 1])?_c('div',{staticClass:"col-md-6 col-12",staticStyle:{"padding-left":"5px"}},[_c('div',{staticClass:"card mb-3",staticStyle:{"background-color":"#050b06"}},[_c('router-link',{attrs:{"to":{
                      name: 'home-episode',
                      params: {
                        pratilipiId: _vm.getSeriesChapters[index + 1].pratilipiId,
                      },
                    }},nativeOn:{"click":function($event){return _vm.triggerClickReadEvent(eachWidget, index+2)}}},[_c('div',{staticClass:"row g-0"},[_c('div',{staticClass:"col-md-4 col-3 w-100 h-100"},[_c('img',{staticClass:"episode-image",attrs:{"src":_vm.getSeriesChapters[index + 1].coverImageUrl,"alt":"..."}})]),_c('div',{staticClass:"col-md-6 col-6"},[_c('p',{staticClass:"card-text text-episode",staticStyle:{"text-align":"left","color":"#fff"}},[_c('small',{staticClass:"text-muted episode-number"},[_vm._v(" एपिसोड्स-"+_vm._s(index + 2))])]),_c('p',{staticClass:"card-text text-start episode-title",staticStyle:{"text-align":"left","color":"#fff"}},[_vm._v(" "+_vm._s(_vm.getSeriesChapters[index + 1].displayTitle)+" ")]),_c('p',{staticClass:"card-text text-start episode-likes",staticStyle:{"text-align":"left"}},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.getSeriesChaptersLikes[index + 1])+" likes")])])])])])],1)]):_vm._e()]):_vm._e()])}),_c('a',{staticClass:"row h-100 h-100",staticStyle:{"background-color":"#050b06"},attrs:{"href":"https://get.pratilipicomics.com/4u9FS2EWJgb"},on:{"click":_vm.triggerClickDownloadEvent}},[(_vm.getSeriesLoadingState === 'LOADING_SUCCESS')?_c('img',{staticStyle:{"max-width":"100%"},attrs:{"src":"https://s3.ap-south-1.amazonaws.com/pratilipi.comic.assets/website/img/download-app-banner-hindi.jpg"}}):_vm._e()])],2)])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"rating-star-img"},[_c('img',{staticClass:"rating-star-img",attrs:{"alt":"","src":"https://comicassets.pratilipi.com/website/img/icon-star.svg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('hr',{staticClass:"Line-Copy"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row no-gutters series-list"},[_c('div',{staticClass:"col-12",staticStyle:{"background-color":"#050b06"}},[_c('h2',{staticClass:"head-title"},[_vm._v("एपिसोड्स")])])])])
}]

export { render, staticRenderFns }