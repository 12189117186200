<template>
  <div class="sharebutton ">
    <div class="social-share">
      <a
        :href="getWhatsAppUri"
        class="whatsapp"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="whatsapp"
        @click="triggerWaEndShareEvent"
        ><span class="social-icon">
          <icon name="whatsapp" />
        </span>
      </a>
      <a
        :href="getFacebookShareUrl"
        class="fb"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="facebook"
        @click="triggerFbEndShareEvent"
      >
        <span class="social-icon">
          <icon name="facebook-f" />
        </span>
      </a>
      <!-- <a
        :href="getTwitterUrl"
        class="twitter"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="twitter"
        @click="triggerTwEndShareEvent"
      >
        <span class="social-icon">
          <icon name="twitter" />
        </span>
      </a> -->

      <a v-if="false" class="link">
        <span class="social-icon">
          <icon name="link" />
        </span>
      </a>
      <input id="inlineShareUri" type="text" hidden /> Share
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import mixins from "@/mixins";
import "vue-awesome/icons/facebook-f";
import "vue-awesome/icons/whatsapp";
import "vue-awesome/icons/twitter";
import "vue-awesome/icons/google-plus";

export default {
  name: "SocialShareStrip",
  mixins: [mixins],
  computed: {
    ...mapGetters([
      "getFacebookShareUrl",
      "getTwitterUrl",
      "getGooglePlusUrl",
      "getWhatsAppUri",
      "getContentUri",
      "getUserDetails",
      "getPratilipiData",
    ]),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    experimentId: {
      type: String,
      required: false,
      default: "CONTROL",
    },
  },
  methods: {
    ...mapActions(["setShareDetails"]),
    triggerFbEndShareEvent() {
      console.log(this.data)
      let pratilipiAnalyticsData = {};
      if (this.getPratilipiData) {
        pratilipiAnalyticsData = this.getPratilipiAnalyticsData(
          this.getPratilipiData
        );
      }
      this.triggerAnanlyticsEvent(`SHAREBOOKFB_BOOKM_BOOK`, this.experimentId, {
       LANGUAGE:"HINDI",
       SCREEN_NAME: "seriesSummaryPage",
       SERIES_NAME: this.data.title,
       SERIES_ID: this.data.seriesId,
        VALUE: "FACEBOOK",
        LOCATION:"Page"
      });
    },
    triggerGpEndShareEvent() {
      let pratilipiAnalyticsData = {};
      if (this.getPratilipiData) {
        pratilipiAnalyticsData = this.getPratilipiAnalyticsData(
          this.getPratilipiData
        );
      }
    },
    triggerTwEndShareEvent() {
      let pratilipiAnalyticsData = {};
      if (this.getPratilipiData) {
        pratilipiAnalyticsData = this.getPratilipiAnalyticsData(
          this.getPratilipiData
        );
      }
      this.triggerAnanlyticsEvent(`SHAREBOOKTW_BOOKM_BOOK`, this.experimentId, {
        ...pratilipiAnalyticsData,
        USER_ID: this.getUserDetails.userId,
        ENTITY_VALUE: "TWITTER",
      });
    },
    triggerWaEndShareEvent() {
      let pratilipiAnalyticsData = {};
      if (this.getPratilipiData) {
        pratilipiAnalyticsData = this.getPratilipiAnalyticsData(
          this.getPratilipiData
        );
      }
      this.triggerAnanlyticsEvent(`SHAREBOOKWA_BOOKM_BOOK`, this.experimentId, {
        LANGUAGE:"HINDI",
       SCREEN_NAME: "seriesSummaryPage",
       SERIES_NAME: this.data.title,
       SERIES_ID: this.data.seriesId,
        VALUE: "WHATSAPP",
        LOCATION:"Page"
      });
    },
  },
  components: {},
  created() {
    this.setShareDetails({ data: this.data, type: this.type });
  },
};
</script>

<style lang="scss" scoped>
.sharebutton {
  color: white;
  width: 432px;
  height: 45px;
  margin: 16px 272px 32px 0px;
  padding: 0px ​305px 44px 127px;
  background-image: linear-gradient(to bottom, #434c45, #202521);
  @media screen and (max-width: 641px) {
    position: absolute;
    right: -263px;
    top: -56px;
    width: 116px;
    height:34px;
    border-radius: 50px;
    padding: 0px 6px 1px 9px;;
  }
}

.social-share {
  width: 128px;
  margin: 5px auto;
  @media screen and (max-width: 641px) {
   margin-left:0px;

  } 
  span.text {
    font-size: 14px;
    color: #555;
    display: block;
    margin-bottom: 5px;
  }
  a {
    vertical-align: middle;
    color: #2c3e50;
    display: inline-block;
    text-align: left;
    margin: 0 0 5px;
    font-size: 14px;
    .social-icon {
      display: inline-block;
      width: 35px;
      height: 35px;
      background: #3b5998;
      color: #fff;
      border-radius: 50%;
      text-align: center;
      line-height: 35px;
      margin-right: 5px;
    @media screen and (max-width: 641px) {
line-height: 20px;
    width: 22px;
    height: 22px;
    }
  }
    .fa-icon {
      vertical-align: middle;
    }
    &:hover {
      text-decoration: none;
    }
  }
}

.social-share a.twitter .social-icon {
  background: #00aced;
}

.social-share a.google .social-icon {
  background: #dd4b39;
}

.social-share a.whatsapp .social-icon {
  background: #48c631;
}

.social-share a.link .social-icon {
  background: #2c3e50;
}
</style>
